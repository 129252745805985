<template>
  <div  v-if="mounted">
    <!--note overriding error color to match the style on this page-->
    <h5 v-if="stepError" style="color:#8b0000">{{ stepError }}</h5>
    <!-- note:  color below was harcoded .  Defaul $primary should be used but there is an apparent bug .   -->
    <form-wizard
      color="#11bbd1"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Submit"
      back-button-text="Previous"
      next-button-text="Next"
      class="vertical-steps steps-transparent mb-3"
      @on-complete="submitManuscriptIntake"
    >
      <tab-content
        title="Upload Manuscript"
        :before-change="validationStep1"
      >
        <h5> Manuscript Submission for Orcid User</h5>
        <p class="text-primary"> {{ $store.state.pubmillGlobalStore.orcidRecord.orcidName }} : {{ $store.state.pubmillGlobalStore.orcidRecord.orcidId }}</p>
        <br>
        <h5> Manuscript Preparation</h5>
        <p>For ease of integration, please consider adding the following text exactly as shown before their respective sections in your document.</p>
        <span class="text-warning">
          <p>[Authors Section]</p>
          <p>[Affiliations Section]</p> 
          <p>[Abstract Section]</p>
          <p>[Full Text Section]</p>
          <p>[References Section]</p>
          <p>[Figures Section]<br>
            <small>Note: Figure images should be pasted directly above their corresponding legend within this section.</small>
          </p>
          <p>[Tables Section]</p>
        </span>
       
        <br>
        <h5> Manuscript Upload</h5>
        <small>Select your file and click Upload</small>
        <br><br>
        <b-row> 
          <b-col cols="6" class="mb-2">
            <b-form-file 
              v-model="fileSelected"
              accept=".docx"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop Now"
              size="lg"
            /> 
          </b-col>
        </b-row>
        <span v-if="manuscriptLoaded">
          <br><br>
          <p> The following title was found on the article:</p>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <h5 class="text-primary" v-html="$sanitize(manuscriptDict['Article Title'])"></h5>
          <br><br>
          <b-row>
            <b-col cols="10" class="mb-2">
              <h5>Please comment on errors, add instructions or questions</h5>
              <quill-editor v-model="notesUploadSection" :options="editorOptions"></quill-editor>
            </b-col>
          </b-row>
        </span>
        <span v-else-if="fileSelected">
          <b-button 
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="primary" 
            @click.stop="uploadManuscriptRequest()"
          >
            <feather-icon
              icon="UploadCloudIcon"
              class="mr-50"
            /> 
            Upload
          </b-button>
        </span> 
      </tab-content>

      <tab-content
        title="all html"
        :before-change="prepForNextTab"
      >
        <span v-if="manuscriptLoaded">
          <b-row>
            <b-col cols="3">
              <span v-if="origDocSelected">
                xx: {{ origDocSelected.nodeName }}
                <br/>
                I: {{ origDocSelected.innerText }}
                <br/>      <br/>
              </span>
            </b-col>
            <b-col cols="9">
              <vue-perfect-scrollbar 
                v-once
                class="scroll-area"  
                :settings="perfectScrollbarSettings" 
              > 
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span @click="handleDomTextClick" v-html="manuscriptDict.orig_doc"></span>
              </vue-perfect-scrollbar>
            </b-col>
          </b-row>
        </span>
      
      </tab-content>
      <tab-content
        title="Authors"
        :before-change="prepForNextTab"
      >
        <b-row  v-if="manuscriptLoaded"> 
          <b-col cols="12" class="mb-2">
            <b-card title="Authors"> 
              <b-row> 
                <b-col v-for="(author, index) in manuscriptDict['Authors']" :key="index" cols="3">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <p class="text-primary" v-html="$sanitize(author)"></p>
                </b-col>
              </b-row>
            </b-card> 
            <b-card title="Affiliations"> 
              <b-row v-for="(affiliation, index) in manuscriptDict['Author Affiliations']" :key="index"> 
                <b-col cols="12">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <p v-html="$sanitize(affiliation)"></p>
                </b-col>
              </b-row>
            </b-card> 
            <b-card> 
              <b-row>
                <b-col cols="10" class="mb-2">
                  <h5>Please comment on errors, add instructions or questions</h5>
                  <quill-editor v-model="notesAuthorsSection" :options="editorOptions"></quill-editor>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content
        title="Author Notes"
        :before-change="prepForNextTab"
      >
        <b-row  v-if="manuscriptLoaded"> 
          <b-col cols="12" class="mb-2">
            <span  v-for="(authorNote, index) in manuscriptDict['Author Notes']" :key="index">
              <b-card> 
                <b-row> 
                  <b-col cols="12">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <p v-html="$sanitize(authorNote)"></p>
                  </b-col>
                </b-row>
              </b-card> 
            </span>
            <b-card> 
              <b-row>
                <b-col cols="10" class="mb-2">
                  <h5>Please comment on errors, add instructions or questions</h5>
                  <quill-editor v-model="notesAuthorMisclSection" :options="editorOptions"></quill-editor>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content
        title="Abstract"
        :before-change="prepForNextTab"
      >
        <b-row  v-if="manuscriptLoaded"> 
          <b-col cols="12" class="mb-2">
            <b-card title="Abstract"> 
              <b-row> 
                <b-col cols="12">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <p v-html="$sanitize(manuscriptDict['Abstract'])"></p>
                </b-col>
              </b-row>
            </b-card> 
            <b-card title="Significance Statement"> 
              <b-row> 
                <b-col cols="12">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <p v-html="$sanitize(manuscriptDict['Significance Statement'])"></p>
                </b-col>
              </b-row>
            </b-card> 
            <b-card> 
              <b-row>
                <b-col cols="10" class="mb-2">
                  <h5>Please comment on errors, add instructions or questions</h5>
                  <quill-editor v-model="notesAbstractSection" :options="editorOptions"></quill-editor>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content
        title="Body"
        :before-change="prepForNextTab"
      >
        <b-row  v-if="manuscriptLoaded"> 
          <b-col cols="12" class="mb-2">
            <b-card v-if="manuscriptBody.length > 0"  title="Body"> 
              <b-row> 
                <b-col cols="12">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <p v-html="manuscriptBody"></p>
                </b-col>
              </b-row>
            </b-card> 
            <b-card  v-if="manuscriptBody.length > 0"  title="Sections"> 
              <span v-for="(sect, index) in Object.keys(manuscriptBodySections)" :key="index"> 
                <!-- eslint-disable-next-line vue/no-v-html -->
                <h5 v-html="sect"></h5>
                <b-row> 
                  <b-col cols="12">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <p v-html="manuscriptBodySections[sect]"></p>
                  </b-col>
                </b-row>
              </span>
            </b-card> 
            <b-card> 
              <b-row>
                <b-col cols="10" class="mb-2">
                  <h5>Please comment on errors, add instructions or questions</h5>
                  <quill-editor v-model="notesBodySection" :options="editorOptions"></quill-editor>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>
            
      <tab-content
        title="Figures"
        :before-change="prepForNextTab"
      >
        <b-row  v-if="manuscriptLoaded"> 
          <b-col cols="12" class="mb-2">
            <b-card title="Figures"> 
              <b-row v-for="(fg, index) in manuscriptFigures" :key="index"> 
                <b-col cols="12">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <p v-html="fg"></p>
                </b-col>
              </b-row>
            </b-card> 
            <b-card> 
              <b-row>
                <b-col cols="10" class="mb-2">
                  <h5>Please comment on errors, add instructions or questions</h5>
                  <quill-editor v-model="notesFiguresSection" :options="editorOptions"></quill-editor>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content
        title="Tables"
        :before-change="prepForNextTab"
      >
        <b-row  v-if="manuscriptLoaded"> 
          <b-col cols="12" class="mb-2">
            <b-card title="Tables"> 
              <b-row v-for="(tb, index) in manuscriptDict['Tables']" :key="index"> 
                <b-col cols="12">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <p v-html="$sanitize(tb)"></p>
                </b-col>
              </b-row>
            </b-card> 
            <b-card> 
              <b-row>
                <b-col cols="10" class="mb-2">
                  <h5>Please comment on errors, add instructions or questions</h5>
                  <quill-editor v-model="notesTablesSection" :options="editorOptions"></quill-editor>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>
            
      <tab-content
        title="References"
        :before-change="prepForNextTab"
      >
        <b-row  v-if="manuscriptLoaded"> 
          <b-col cols="12" class="mb-2">
            <b-card title="References"> 
              <b-row> 
                <b-col cols="12">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <p v-html="$sanitize(manuscriptDict['References'])"></p>
                </b-col>
              </b-row>
            </b-card>
            <b-card> 
              <b-row>
                <b-col cols="10" class="mb-2">
                  <h5>Please comment on errors, add instructions or questions</h5>
                  <quill-editor v-model="notesReferencesSection" :options="editorOptions"></quill-editor>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content
        title="Other"
        :before-change="prepForNextTab"
      >
        <b-row  v-if="manuscriptLoaded"> 
          <b-col cols="12" class="mb-2">
            <b-card title="Acknowledgements"> 
              <b-row> 
                <b-col cols="12">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <p v-html="$sanitize(manuscriptDict['Acknowledgements'])"></p>
                </b-col>
              </b-row>
            </b-card> 
            <b-card title="Keywords"> 
              <b-row> 
                <b-col cols="12">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <p v-html="$sanitize(manuscriptDict['Keywords'])"></p>
                </b-col>
              </b-row>
            </b-card> 
            <b-card> 
              <b-row>
                <b-col cols="10" class="mb-2">
                  <h5>Please comment on errors, add instructions or questions</h5>
                  <quill-editor v-model="notesOtherSection" :options="editorOptions"></quill-editor>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import fileMgrApi from '@/dl_pubmill/apis/fileMgr'
import publicationApi from '@/dl_pubmill/apis/publication'
import Ripple from 'vue-ripple-directive'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

import { 
  BRow, BCol, BButton, BFormFile, BCard
} from 'bootstrap-vue'

export default {
  components: {
    FormWizard,
    TabContent,
    BButton,
    BRow,
    BCol,
    BFormFile,
    BCard,
    quillEditor,
    VuePerfectScrollbar
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      stepError: null,
      fileSelected: null,
      webImgeDict: null,
      manuscriptDict: null,
      origDocSelected: null,
      manuscriptBody: null,
      manuscriptBodySections: null,
      manuscriptFigures: null,
      notesUploadSection: null,
      notesAuthorsSection: null,
      notesAuthorMisclSection: null,
      notesAbstractSection: null,
      notesBodySection: null,
      notesFiguresSection: null,
      notesTablesSection: null,
      notesReferencesSection: null,
      notesOtherSection: null,
      mounted: null,
      manuscriptLoaded: null,
      editorOptions: {
        modules: {
          toolbar: [
            [{ header: [1, 2, false] }],
            ['bold'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ font: [] }],
            [{ color: [] }],
          ]
        }
      },
      perfectScrollbarSettings: {
        maxScrollbarLength: 500,
      },
    }
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  mounted() {
    if (!this.$store.state.pubmillGlobalStore.orcidRecord) {
      if (!sessionStorage.getItem('orcidId')) {
        //this.$router.push({ name: 'error-404' })
        this.$router.push({ name: 'dl-auth-orcid-login', params: { function: 'submit-manuscript', pubIssn: this.$route.params.issn } })
        return
      }
      //
      const orcidRecord = {
        orcidId: sessionStorage.getItem('orcidId'),
        orcidName: sessionStorage.getItem('orcidName'),
      }
      this.$store.commit('pubmillGlobalStore/updateOrcidRecord', orcidRecord)
    }
    this.mounted = true
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  methods: {
    imageLinkOn (imageName) {
      const rec = this.webImgeDict[imageName]
      if (rec) {
        return rec.url 
      } 
      return ''
    },
    initFiguresAndInlineImagesInBody () {
      //'<pbm-img-wrapper pbm-img-name="pnas.2116730119fig01"></pbm-img-wrapper>'
      //find and replace all pbm-img-wrapper  ( these will be within figures or inline) 
      
      let nodes = this.manuscriptBody.split('<pbm-img-wrapper ')
      for (let i = 0; i < nodes.length; i++) {
        if (i === 0) continue
        const wrapper = '<pbm-img-wrapper '.concat(nodes[i].split('</pbm-img-wrapper>')[0], '</pbm-img-wrapper>')
        const imgName = wrapper.split('pbm-img-name="')[1].split('">')[0]
        const src =  this.imageLinkOn(imgName)
        const newWrapper = '<pbm-img-wrapper '.concat('pbm-img-name="', imgName, '"> <img src="', src, '" style="padding: 10px;" class="img-fluid">', '</pbm-img-wrapper>')
        this.manuscriptBody = this.manuscriptBody.replace(wrapper, newWrapper)
      } 
  
      const sectKeys = Object.keys(this.manuscriptBodySections)
      for (let h = 0; h < sectKeys.length; h++) {
        const section = this.manuscriptBodySections[sectKeys[h]]
        nodes = section.split('<pbm-img-wrapper ')
        for (let i = 0; i < nodes.length; i++) {
          if (i === 0) continue
          const wrapper = '<pbm-img-wrapper '.concat(nodes[i].split('</pbm-img-wrapper>')[0], '</pbm-img-wrapper>')
          const imgName = wrapper.split('pbm-img-name="')[1].split('">')[0]
          const src =  this.imageLinkOn(imgName)
          const newWrapper = '<pbm-img-wrapper '.concat('pbm-img-name="', imgName, '"> <img src="', src, '" style="padding: 10px;" class="img-fluid">', '</pbm-img-wrapper>')
          this.manuscriptBodySections[sectKeys[h]] = section.replace(wrapper, newWrapper)
        } 
      }
      //console.log('fffff', this.manuscriptBodySections)
      for (let h = 0; h < this.manuscriptFigures.length; h++) {
        const rec = this.manuscriptFigures[h]
        nodes = rec.split('<pbm-img-wrapper ')
        for (let i = 0; i < nodes.length; i++) {
          if (i === 0) continue
          const wrapper = '<pbm-img-wrapper '.concat(nodes[i].split('</pbm-img-wrapper>')[0], '</pbm-img-wrapper>')
          const imgName = wrapper.split('pbm-img-name="')[1].split('">')[0]
          const src =  this.imageLinkOn(imgName)
          const newWrapper = '<br><br><br><pbm-img-wrapper '.concat('pbm-img-name="', imgName, '"> <img src="', src, '" style="padding: 10px;" class="img-fluid">', '</pbm-img-wrapper>')
          this.manuscriptFigures[h] = rec.replace(wrapper, newWrapper)
        } 
      }
      this.manuscriptLoaded = true

    }, 
    handleDomTextClick(e) {
      
      //const selectedText = window.getSelection()
      //const range = selectedText.getRangeAt(0)
      //console.log('hhhh', selectedText, range)
      if (e.target.nodeName === 'SPAN') return
      if (this.origDocSelected) this.origDocSelected.style.backgroundColor = null
      this.origDocSelected = e.target
      //console.log('ffff121', this.origDocSelected.style.backgroundColor)
      this.origDocSelected.style.backgroundColor = '#FDFF47'
      //console.log('fff', e.target)
      const classList = e.target.classList
      for (let i = 0;  i < classList.length; i++) {
        const className  = classList.item(i)
        if (className.includes('pbm-annotation-')) {
          const noteIdClickedIntext = className.split('pbm-annotation-')[1].split('"')[0]
          this.annotationMarkerClicked(noteIdClickedIntext)
        }
      } 
    },
    formSubmitted() {
    },
    prepForNextTab () {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return true
    },
    validationStep1() {
      this.stepError = null
      if (this.fileSelected == null) {
        this.stepError = 'Please select a manuscript to upload.'
        return false
      } 
      if (this.manuscriptDict == null) {
        this.stepError = 'Please upload the selected file.'
        return false
      } 
      return this.prepForNextTab()
    },
    uploadManuscriptRequest () {
      this.stepError = null
      this.manuscriptDict = null
      console.log('this.fileSelected', this.fileSelected.length, this.fileSelected)
      const queryParams = {
        issn: this.$route.params.issn,
        rawFile: this.fileSelected
      }
      //let file = this.fileName.trim()
      //file = file.replace(/\s/g, '_')
      fileMgrApi.uploadManuscriptRequest(queryParams, this, null) 
    },
    backFromUploadManuscriptRequest (serverData) {
      console.log('backFromUploadManuscriptRequest', serverData)
      if (serverData.error) {
        console.log('serverData.error', serverData.error)
        this.$bvModal
          .msgBoxOk(serverData.error, {
            title: 'Sorry. There was a problem with the upload',
            size: 'sm',
            okVariant: 'danger',
            modalClass: 'modal-danger',
            okTitle: 'Ok',
          })
      } else { 
        console.log('back from', this.fileSelected.name)
        this.intakeManuscript(this.fileSelected.name)
      }
    },
    intakeManuscript (fileName) {
      publicationApi.intakeManuscript(this.$route.params.issn, fileName, this.$store.state.pubmillGlobalStore.orcidRecord.orcidId, this.$store.state.pubmillGlobalStore.orcidRecord.orcidName, this, null)
    },
    backFromIntakeManuscript (serverData) {
      if (serverData.error) {
        this.$bvModal
          .msgBoxOk(serverData.error, {
            title: 'Sorry. There was a problem with the manuscript intake',
            size: 'sm',
            okVariant: 'danger',
            modalClass: 'modal-danger',
            okTitle: 'Ok',
          })
      } else {
        console.log('backFromIntakeManuscript', serverData)
        this.manuscriptBody = serverData.Body
        this.manuscriptBodySections = {}
        const sectKeys = Object.keys(serverData['Body Sections'])
        for (let i = 0; i < sectKeys.length; i++) {
          this.manuscriptBodySections[sectKeys[i]] = serverData['Body Sections'][sectKeys[i]]
        }
        this.manuscriptFigures = []
        for (let i = 0; i < serverData.Figures.length; i++) {
          this.manuscriptFigures.push(serverData.Figures[i])
        }
    
        this.manuscriptDict = serverData
        this.getWebImageUrls()
      } 
    }, 
    submitManuscriptIntake () {
      publicationApi.submitManuscriptIntake(this.$route.params.issn, this.fileSelected.name, this.$store.state.pubmillGlobalStore.orcidRecord.orcidId, this.$store.state.pubmillGlobalStore.orcidRecord.orcidName, this, null)
    },
    backFromSubmitManuscriptIntake (serverData) {
      if (serverData.error) {
        this.$bvModal
          .msgBoxOk(serverData.error, {
            title: 'Sorry. There was a problem with the manuscript intake',
            size: 'sm',
            okVariant: 'danger',
            modalClass: 'modal-danger',
            okTitle: 'Ok',
          })
      } else {

        this.$bvModal
          .msgBoxOk('A representative from the journal team will be in touch with you shortly.', {
            title: 'Manuscript Submission Complete',
            size: 'sm',
            okVariant: 'success',
            modalClass: 'modal-success',
            okTitle: 'Ok',
          }) 
          .then(value => {
            if (value) {
              //this.$router.push({ name: 'my-publications' })
              window.location.href = 'https://Versionofrecord.com'
            }
          })
      } 
    },
    getWebImageUrls () {
       
      const queryParams = {
        issn: this.$route.params.issn,
        file_status: 'manuscript',
        image_names: this.manuscriptDict.imageNames,
        folder: this.fileSelected.name.split('.docx')[0],
        size: 'medium'
      } 
      fileMgrApi.getWebImageUrls(queryParams, this, null)
    },
    backFromGetWebImageUrls (serverData) {
      if (serverData.error) {
        console.log('serverData.error', serverData.error)
        this.$bvModal
          .msgBoxOk(serverData.error, {
            title: 'Sorry. There was a problem with the image retrieval',
            size: 'sm',
            okVariant: 'danger',
            modalClass: 'modal-danger',
            okTitle: 'Ok',
          })
      } else {
        //console.log('iiiiii', serverData)
        this.webImgeDict =  serverData
        this.initFiguresAndInlineImagesInBody()
      } 
    },

  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  table,
  th,
  td {
    padding: 10px;
    border: 1px solid black;
    border-collapse: collapse;
  }
  .scroll-area {
    position: relative;
    margin: auto;
    height: 800px;
  }
</style>
